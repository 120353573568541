
















































































































import { vxm } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import ReminderRow from "@/views/widgets/ReminderRow.vue";

import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import YesNoModal from "@shared_vue/components/YesNoModal.vue";
import {
  Configuration,
  ReminderApiFactory,
  ReminderDTO,
} from "@shared_vue/openapi/myvaultapi";

@Component({
  components: { ReminderRow, WaitModal, YesNoModal },
})
export default class ReminderPage extends Vue {
  private ui = vxm.ui;
  // private reminderApi = ReminderApi.getInstance();
  private reminderList: ReminderDTO[] = [];
  private reminderApi2 = ReminderApiFactory(
    <Configuration>{ basePath: process.env.VUE_APP_MYVAULT_API_URL },
    process.env.VUE_APP_MYVAULT_API_URL
  );
  private showWait: boolean = false;
  private showYesNoDelete: boolean = false;
  private tempId = 0;
  private newName: string = "";
  private newCat: any = 0;
  private showNewReminder1: boolean = false;
  private showNewReminder2: boolean = false;
  private newDescription: string = "";
  private showEditModal: boolean = false;
  private tempDTO = <ReminderDTO>{};
  private catoptions = [{ value: 1, label: "Category 1" }, { value: 2, label: "Category 2" }];

  private submitNew() {
    console.log("saving...");
    //add waiting modal
    let newReminderDTO = <ReminderDTO>{};
    newReminderDTO.category = this.CatName; // should we use ID? idk
    newReminderDTO.description = this.newDescription;
    newReminderDTO.name = this.newName;
    this.reminderApi2
      .reminderPostReminder(newReminderDTO)
      .then((result) => {
        console.log(result);
        this.cancel();
        this.fetchAll();
      })
      .catch((err) => console.log(err));
  }

  private submitUpdate() {
    this.reminderApi2
      .reminderPutReminder(this.tempDTO)
      .then((result) => {
        console.log(result);
        this.showEditModal = false;
        this.tempDTO = <ReminderDTO>{};
        this.fetchAll();
      })
      .catch((err) => console.log(err));
  }

  get CatName() {
    return this.catoptions.find((a) => a.value == this.newCat)?.label ?? "";
  }

  get CatVal() {
    return (
      this.catoptions.find((a) => a.label == this.tempDTO.category)?.value ?? ""
    );
  }

  private manageReminder(id: number) {
    this.tempId = id;
    this.tempDTO = this.reminderList.find((r) => r.id == this.tempId)!;
    this.showEditModal = true;
  }

  private next1() {
    this.showNewReminder1 = false;
    this.showNewReminder2 = true;
  }

  private newReminder() {
    this.showNewReminder1 = true;
  }
  private deleteReminder(id: number) {
    this.showYesNoDelete = true;
    this.tempId = id;
  }

  private deleteConfirm() {
    this.reminderApi2
      .reminderDeleteReminder(this.tempId)
      .then((resp) => {
        console.log(resp.data);
        //add version stuff here :-O
        this.cancel();
        this.fetchAll();
      })
      .catch((err) => {
        // Handle Error Here
        console.error(err);
      })
      .finally(() => {
        this.showYesNoDelete = false;
      });
  }

  private cancel() {
    this.showEditModal = false;
    this.showNewReminder1 = false;
    this.showNewReminder2 = false;
    this.newDescription = "";
    this.newName = "";
    this.newCat = null;
  }

  private fetchAll() {
    this.showWait = true;
    // this.reminderApi.getReminders().then(resp => {
    //   console.log(resp.data);
    //   //add version stuff here :-O
    //   this.reminderList=resp.data;
    // })
    //     .catch(err => {
    //       // Handle Error Here
    //       console.error(err);
    //     }).finally(()=>{
    //   this.showWait=false;
    // });
    this.reminderApi2
      .reminderGetReminders()
      .then((resp) => {
        console.log(resp.data);
        //add version stuff here :-O
        this.reminderList = resp.data;
      })
      .catch((err) => {
        // Handle Error Here
        console.error(err);
      })
      .finally(() => {
        this.showWait = false;
      });
  }
  mounted() {
    this.fetchAll();
  }
}
