















































import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';
import {ReminderDTO} from "@shared_vue/types/ReminderDTO";


@Component
export default class ReminderRow extends Vue {

  @Prop() readonly reminder: ReminderDTO | undefined
  @Prop() readonly clickFunc: Function | undefined
  @Prop() readonly delFunc: Function | undefined
  private ui = vxm.ui;


}
